import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import NavbarLink from 'common/src/containers/Saas/NavbarLink';

import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Helmet from 'react-helmet';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="CGU" />
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
        })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'GTM-K7ZDQRW':true});`,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-85201087-1', 'auto');
        ga('require', 'GTM-K7ZDQRW');
        ga('send', 'pageview');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2028529634034124');
        fbq('track', 'PageView');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
            0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
            for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
        mixpanel.init("f1abedc49a5e762f6b4550441d0849b6");`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function (d, w, c) { (w[c] = w[c] || []).push(function() { try { w.yaCounter46174806 = new Ya.Metrika({ id:46174806, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true }); } catch(e) { } }); var n = d.getElementsByTagName("script")[0], s = d.createElement("script"), f = function () { n.parentNode.insertBefore(s, n); }; s.type = "text/javascript"; s.async = true; s.src = "https://mc.yandex.ru/metrika/watch.js"; if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "yandex_metrika_callbacks");`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=2028529634034124&ev=PageView&noscript=1"
          />`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<div><img src="https://mc.yandex.ru/watch/46174806" style="position:absolute; left:-9999px;" alt="" /></div>`,
          }}
        />

        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <NavbarLink />
            </DrawerProvider>
          </Sticky>
          <div
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: '150px',
              marginBottom: '50px',
              maxWidth: '800px',
              width: '100%',
            }}
          >
            <em>
              Conditions Générales d’Utilisation révisées en date du 01/10/2013.
            </em>
            <br />
            <h2>La société INFLUA</h2>
            Inscrite au RCS de Bordeaux sous le numéro 791 059 215. Dont le
            siège social est Rue Cantelaudette – Immeuble Titanium – 33310
            Lormont - France Représentée par M LIBES Jérôme, PDG Dûment
            habilitée aux fins des présentes, Et désignée ci-après L’EDITEUR
            L’adhésion au service par Le CLIENT entraîne l’acceptation expresse
            et sans réserve par celui-ci des présentes Conditions Générales
            d’Utilisation, et exclut l’application de toutes dispositions
            différentes ou contraires pouvant figurer sur des documents
            commerciaux ou sur les conditions générales d’achat du Client.
            L’acceptation du Client et la formation de la présente Convention
            sont matérialisés par la signature électronique du Client,
            concrétisée par le « clic de validation ». Cette signature
            électronique a valeur de signature manuscrite entre les parties.
            Cette démarche équivaut pour le Client à reconnaître qu’il a pris
            pleinement connaissance et qu’il approuve, sans réserve, l’ensemble
            des Conditions Générales d’Utilisation indiquées ci-après. Sauf dans
            les cas limitativement prévus par les présentes Conditions Générales
            d’Utilisation, l’adhésion signée électroniquement par le Client, par
            le biais du  « clic de validation », ou le bon de commande signé par
            le Client, vaut commande définitive et ne peut être rétracté. Dans
            ces conditions, L’EDITEUR invite les Clients qui se sont connectés
            sur son site{' '}
            <a href="https://www.startmystory.fr">www.startmystory.fr</a> à lire
            attentivement les Conditions Générales d’Utilisation ci-après. Les
            Conditions Générales d’Utilisation décrites ci-après s’appliquent
            également aux périodes d’utilisation gratuites. Les dispositions des
            présentes Conditions Générales d’Utilisation s’appliquent entre
            L’EDITEUR et ses Clients quel qu’ait été le canal de vente.
            <h2>PREAMBULE : DEFINITIONS</h2>
            <ul>
              <li>
                <strong>Solutions</strong> désigne les fonctions opérationnelles
                listées en annexe du contrat et mises à disposition du Client
                dans le cadre des Service Applicatifs objets du contrat ;
              </li>
              <li>
                <strong>Données</strong> désignent les informations,
                publications et, de manière générale, les données de la base de
                données Client dont l'utilisation est l'objet du présent
                contrat, pouvant être consultées uniquement par les Utilisateurs
                ;
              </li>
              <li>
                <strong>Identifiants</strong> désignent tant l'identifiant
                propre de l’utilisateur ("login") que le mot de passe de
                connexion ("password"), communiqués après inscription au Service
                Applicatif ;
              </li>
              <li>
                <strong>Internet</strong> désigne l’ensemble de réseaux
                interconnectés, lesquels sont localisés dans toutes les régions
                du monde ;
              </li>
              <li>
                <strong>Intranet</strong> désigne le réseau informatique propre
                à une entreprise ou une organisation, utilisant les protocoles
                TCP/IP et, plus généralement, les technologies de l’Internet et
                pouvant être relié au réseau Internet ;
              </li>
              <li>
                <strong>Logiciel</strong> désigne tout logiciel fourni par
                L’EDITEUR au Client et en particulier les Solutions associées.
              </li>
              <li>
                <strong>Service Applicatif </strong>désigne le Service
                Applicatif proposé en mode SaaS par L’EDITEUR, permettant
                l’utilisation des Solutions par le Client ;
              </li>
              <li>
                <strong>Utilisateur</strong> désigne la personne placée sous la
                responsabilité du Client (préposé, salarié, représentant, etc.)
                et bénéficiant d’un accès aux Service Applicatifs sur son
                ordinateur, tablette ou smartphone en vertu de la licence
                d’utilisation contractée par le Client.
              </li>
            </ul>
            <ol>
              <li>
                <strong>
                  <u>OBJET ET ETENDUE</u>
                </strong>
              </li>
            </ol>
            L’objet de la présente convention est de fixer les conditions
            générales d’utilisation des Service Applicatifs disponibles dans le
            cadre de la solution StartMyStory éditée et intégrée par L’EDITEUR,
            tant en ce qui concerne l’application elle-même que tout Service
            Applicatif associé, ainsi que les droits et obligations des parties.
            L’EDITEUR consent au Client, qui accepte :
            <ul>
              <li>
                - un droit d'accès aux serveurs choisis par L’EDITEUR dans les
                conditions définies ci-après ;
              </li>
              <li>- un droit d'utilisation finale des Solutions ;</li>
              <li>
                - un ensemble de Service Applicatifs ci-après définis, notamment
                d’hébergement des données, de maintenance des Service
                Applicatifs applicatifs, d’assistance technique.
              </li>
            </ul>
            L’EDITEUR sous-traite l’hébergement des données sur des serveur en
            France, et assure la maintenance et la sécurité des solutions. Sauf
            disposition expresse contraire, toute nouvelle caractéristique qui
            améliore ou augmente un ou plusieurs Service Applicatifs existants
            ou tout nouveau Service Applicatif seront soumis aux présentes
            Conditions. Des conditions particulières à certains produits ou
            Service Applicatifs peuvent compléter les présentes conditions
            générales. Elles sont alors précisées lors de la présentation du
            produit ou du Service Applicatif.
            <ol start="2">
              <li>
                <strong>
                  <u>PARTIES</u>
                </strong>
              </li>
            </ol>
            LE CLIENT est l'unique cocontractant de L’EDITEUR pour l'exécution
            des présentes conditions. Le règlement des sommes dues s’effectuera
            auprès de L’EDITEUR auquel le CLIENT est adhérent, selon les
            conditions financières prévues au moment de l’adhésion. Le CLIENT
            peut représenter plusieurs entités sous réserve d’en être
            explicitement mandaté. LE CLIENT s’engage à exclusivement exploiter
            le Service Applicatif pour des besoins professionnels et à détenir
            toute qualité, être titulaire de toute autorisation, agrément, etc…
            relatif à l’activité exercée dans le cadre de laquelle l’application
            et le Service Applicatif sont exploités, et garantit que toute
            entité qu’il représente respecte les mêmes obligations. Le CLIENT ne
            peut en aucun cas transmettre à titre onéreux ou gratuit, totalement
            ou partiellement, sous quelque forme que ce soit, la convention ou
            le bénéfice de l'utilisation de la convention, ou substituer un
            tiers dans l'exécution de ses obligations, sans le consentement
            express et préalable de l’EDITEUR.
            <ol start="3">
              <li>
                <strong>
                  <u>UTILISATION</u>
                </strong>
              </li>
            </ol>
            La qualité d’UTILISATEUR induit l'acceptation irrévocable et
            définitive de l’intégralité des conditions générales. Tout
            manquement à ces règles pourra entraîner la suspension ou la
            résiliation du compte et du droit d’accès ainsi que le refus
            immédiat et pour le futur de l'accès à tout ou partie du Service
            Applicatif. LE CLIENT pourra choisir de disposer de Prestations
            Complémentaires facturées au tarif en vigueur au jour de la commande
            et fournies par L’EDITEUR sur simple demande. Les nouvelles
            Prestations Complémentaires souscrites seront matérialisées par un
            Devis signé complétant la présente Convention.
            <ol start="4">
              <li>
                <strong>
                  <u>DESCRIPTION</u>
                </strong>
              </li>
            </ol>
            La solution STARTMYSTORY fournit un Service Applicatif en ligne,
            destiné à la réalisation du business plan de l’entreprise du CLIENT
            et à sa diffusion sur différents supports. L’EDITEUR assure la mise
            à disposition, la maintenance corrective et évolutive, et la
            sécurité des solutions.
            <ol start="5">
              <li>
                <strong>
                  <u>PRE-REQUIS TECHNIQUES</u>
                </strong>
              </li>
            </ol>
            Afin d'utiliser le Service Applicatif, il est nécessaire de
            bénéficier d’un accès à Internet (les éventuels frais correspondants
            sont à la charge de l’UTILISATEUR). Il est indispensable de disposer
            de tout matériel nécessaire, et notamment d'un terminal
            informatique, d'un modem ou de tout autre outil de connexion, afin
            d'assurer une connexion au réseau Internet et au téléchargement de
            données, fichiers et contenus numériques. Une connexion Haut débit
            d’un débit garanti de 1Gb/s est nécessaire. Le Service Applicatif
            est optimisé pour un affichage 1024*768 et pour les navigateurs
            courants sur le marché pour PC et Macintosh. La compatibilité du
            Service Applicatif avec un autre type de configuration ne peut être
            garantie. L’EDITEUR se réserve le droit de conseiller un navigateur
            plus adapté en cas de difficultés rencontrées par Le CLIENT.
            L'UTILISATEUR devra s’assurer  que sa configuration informatique ne
            contient aucun virus et qu'elle est en parfait état de
            fonctionnement. Dans le cas où l’utilisation du Service Applicatif
            nécessiterait le téléchargement d’une application spécifique,
            l'UTILISATEUR s’engage à ne pas installer, copier ou utiliser cette
            application avant d’avoir préalablement acquiescé aux termes de la
            licence correspondante. L'UTILISATEUR déclare que le coût de l'accès
            et de la navigation sur ce Service Applicatif est à sa charge
            exclusive..
            <ol start="6">
              <li>
                <strong>
                  <u>DISPONIBILITE</u>
                </strong>
              </li>
            </ol>
            L'UTILISATEUR reconnaît avoir été informé que le Service Applicatif
            est accessible 24h/24h et 7 jours/7, à l'exception des cas de force
            majeure, difficultés informatiques, difficultés liées aux réseaux de
            télécommunications ou difficultés techniques, et plus généralement
            toute interruption temporaire involontaire. Pour des raisons de
            maintenance, L’EDITEUR pourra interrompre l'accès au Service
            Applicatif et s'efforcera d'en avertir préalablement les
            UTILISATEURS au moins 24h à l’avance. Les opérations de maintenance
            du  Service Applicatif seront effectuées dans la mesure du possible
            sans interruption de l’accès au dit Service. Compte tenu de la
            nature particulière de l’Internet, L’EDITEUR ne saurait être tenu
            pour responsable des dommages directs ou indirects qui pourraient
            résulter notamment :
            <ul>
              <li>
                Des vitesses d'accès depuis d'autres sites dans le monde, de
                ralentissements, ou de difficultés d'accès aux données du
                CLIENT,
              </li>
              <li>
                Du non-acheminement de transmissions électroniques, du fait des
                mêmes limitations et caractéristiques de l'Internet,
              </li>
              <li>
                D’une altération des informations ou des données durant le
                transfert par un quelconque moyen de télécommunication ou par
                tous autres moyens,
              </li>
              <li>
                De la perte, de l’altération ou de toute utilisation frauduleuse
                de données, de la transmission accidentelle de virus ou autres
                éléments nuisibles, de l’attitude ou comportement d’un tiers, de
                la non-conclusion d’une opération.
              </li>
              <li>
                De la contamination par virus des données et/ou logiciels du
                client, dont la protection incombe à ce dernier;
              </li>
              <li>
                Des intrusions malveillantes de tiers sur l’espace du client ou
                du piratage des données du client, malgré les mesures
                raisonnables de sécurité mises en place,
              </li>
              <li>
                Des dommages que pourraient subir les équipements connectés du
                CLIENT, ceux-ci étant placés sous l’entière responsabilité du
                CLIENT, dommages quelconques, directs ou indirects, matériels ou
                immatériels résultant notamment de la consultation et/ou de
                l'utilisation du Service Applicatif (ou d'autres Service
                Applicatifs qui lui sont liés) et des éventuelles applications
                en téléchargement, comme de l'utilisation des informations
                textuelles ou visuelles, qui auraient pu être recueillies et
                notamment de tout préjudice financier ou commercial, de pertes
                de programmes ou de données dans son système d'information,
              </li>
              <li>
                Des détournements éventuels des mots de passe, codes
                confidentiels, et plus généralement de toute information à
                caractère sensible pour Le CLIENT.
              </li>
            </ul>
            L’UTILISATEUR reconnaît être informé que l’hébergement contracté par
            INFLUA est un hébergement partagé dit « multitenant » avec dès lors
            une mise à jour mutualisée.
            <ol start="7">
              <li>
                <strong>
                  <u>COMPTE D’UTILISATEUR, MOT DE PASSE ET SECURITE</u>
                </strong>
              </li>
            </ol>
            LE CLIENT et tout UTILISATEUR du Service Applicatif s’engagent,
            comme demandé dans le formulaire d'inscription ou lors de toute
            autre demande d'information nécessaire au bon fonctionnement du
            Service Applicatif en général :
            <ul>
              <li>
                A fournir des informations réelles, exactes, à jour et
                complètes;
              </li>
              <li>
                A maintenir et remettre à jour régulièrement les données
                d’inscription afin de les conserver réelles, exactes, à jour et
                complètes.
              </li>
            </ul>
            Dans l'hypothèse où les informations fournies seraient fausses,
            inexactes, périmées ou incomplètes, L’EDITEUR serait en droit de
            suspendre ou résilier de plein droit et sans préavis la présente
            convention par lettre recommandée avec accusé de réception. Au
            moment de sa première connexion, chaque UTILISATEUR se voit
            attribuer un identifiant et un mot de passe strictement personnels,
            individuels et incessibles. Ces informations permettent l’accès
            assorti d’un niveau d’habilitation choisi par LE CLIENT. LE CLIENT
            pourra librement, via le Service Applicatif, désactiver ou changer
            le mot de passe de ses UTILISATEURS et ce en temps réel.
            L’UTILISATEUR est seul responsable de la conservation du caractère
            confidentiel du compte et du mot de passe et de toutes actions qui
            pourront être faites sous l’identification du compte et/ou avec le
            mot de passe. Ces informations ne doivent en aucun cas être
            divulguées. L’UTILISATEUR s’engage :
            <ul>
              <li>
                A informer immédiatement par mail confirmé par courrier
                L’EDITEUR de tout vol ou perte de son mot de passe, de toute
                utilisation non autorisée du compte et/ou du mot de passe et de
                toute atteinte à la sécurité dont il a connaissance. La
                déclaration de perte ou de vol pourrait entraîner la destruction
                pure et simple des fichiers et informations stockés, sans aucun
                droit à indemnisation, ce dont l’utilisateur est parfaitement
                informé.
              </li>
              <li>
                A s’assurer qu'il se déconnecte du Service Applicatif à l'issue
                de chaque session d’utilisation.
              </li>
            </ul>
            Dans l'hypothèse où l’UTILISATEUR décide néanmoins de communiquer
            ces informations à des tiers sans informer L’EDITEUR, cette dernière
            serait en droit de suspendre ou de résilier le compte et de refuser
            immédiatement et pour le futur l'accès à tout ou partie du Service
            Applicatif. L’EDITEUR ne pourra être tenu responsable de toute perte
            ou dommage survenant en cas de manquement aux obligations du présent
            article.
            <ol start="8">
              <li>
                <strong>
                  <u>EXPLOITATION DES DONNEES</u>
                </strong>
              </li>
            </ol>
            Les données fournies et diffusées par LE CLIENT demeurent son
            entière propriété et bénéficie à ce titre d’un droit exclusif de
            modification de ses données. LE CLIENT a le droit d’enregistrer ses 
            données. L’EDITEUR ne saurait être tenu responsable du contenu des
            informations enregistrées et diffusées par LE CLIENT. L’EDITEUR
            n'exerce pas de contrôle sur le contenu émis par Le CLIENT, que ce
            soit à travers son espace ou les informations qu'il serait amené à
            diffuser. En aucun cas L’EDITEUR ne saurait voir sa responsabilité
            engagée à la suite de toute action ou recours de tiers, notamment du
            fait:
            <ul>
              <li>
                d'informations, de sons, d'images, de textes et de tout contenu
                contraires aux législations et réglementations en vigueur ou
                attentatoires aux droits d’autrui, contenus et/ou diffusés sur
                par Le CLIENT.
              </li>
              <li>
                de la violation des droits de propriété intellectuelle relatifs
                aux œuvres contenues ou diffusées, en intégralité ou
                partiellement par Le CLIENT.
              </li>
            </ul>
            Le cas échéant, L’EDITEUR se réserve le droit, à tout moment et sans
            préavis, de suspendre l’accès par quelque moyen que ce soit à tout
            contenu qu'il estimerait non-conforme, ou bien dont l'usage qui en
            est fait n'est pas estimé conforme aux législations et
            réglementations en vigueur. Par ailleurs, L’EDITEUR ne saurait voir
            sa responsabilité engagée à la suite de la suspension et/ou de la
            résiliation des comptes, notamment à la suite du non-règlement des
            sommes dues en exécution des présentes, et plus généralement du fait
            de l’inexécution de l’une quelconque des obligations du client
            telles que fixées par les présentes. Le CLIENT devra garantir
            L’EDITEUR de toute condamnation éventuelle à ce titre. Le CLIENT est
            informé de la nécessité, aux termes de la loi°78-17 du 6 janvier
            1978 relative à l'Informatique, aux fichiers et aux libertés, de
            respecter les obligations relatives aux traitements automatisés de
            données personnelles mis en œuvre dans le cadre de l'exploitation de
            la solution. &nbsp;
            <ol start="9">
              <li>
                <strong>
                  <u>SECURITE DES DONNEES</u>
                </strong>
              </li>
            </ol>
            L’EDITEUR s'engage à respecter la plus stricte confidentialité et
            l'intégrité des données du CLIENT et des UTILISATEURS, et à mettre
            en œuvre ses meilleurs moyens pour en assurer la sauvegarde. Afin de
            maintenir une qualité de Service Applicatif optimale, notamment en
            privilégiant les temps de réponse des Solutions. L’EDITEUR procède à
            l’archivage régulier des données du CLIENT. L’EDITEUR pourra
            délivrer au CLIENT une copie au format Excel ou XML des données
            archivées. LE CLIENT fera alors son affaire de leur sort et dégage,
            à ce titre, L’EDITEUR de toute responsabilité.
            <ol start="10">
              <li>
                <strong>
                  <u>SUPPORT TELEPHONIQUE</u>
                </strong>
              </li>
            </ol>
            L’EDITEUR s’efforcera de résoudre par e-mail, par téléphone ou par
            télémaintenance les difficultés rencontrées par les UTILISATEURS du
            Service Applicatif, qui pourront contacter l’assistance au moyen du
            formulaire contact indiqué dans le Service Applicatif. Les demandes
            seront reçues les jours ouvrables du lundi au vendredi de 9h à 12h
            et de 14h à 17h30. Sont exclus de l’assistance les questions qui ne
            seraient pas directement liées aux Services Applicatifs et toute
            demande qui révèlerait une insuffisance de formation initiale des
            UTILISATEURS sur l’Application agissant sous l'autorité et sous la
            responsabilité du CLIENT ou d’un UTILISATEUR. Dans les cas où
            l’assistance fournie à un UTILISATEUR au cours d’une même journée
            dépasserait un total de 45 minutes, L’EDITEUR pourra proposer,
            suivant devis établi au tarif de soixante (60) euros hors taxes de
            l’heure, un accompagnement personnalisé, ou une autre forme de
            formation à distance.
            <ol start="11">
              <li>
                <strong>
                  <u>PRIX ET PAIEMENT</u>
                </strong>
              </li>
            </ol>
            Les prix des prestations est le suivant : gratuit pour toute la
            durée d’utilisation des Services. Les Prestations Complémentaires
            seront facturées mensuellement, trimestriellement, ou
            semestriellement selon leur nature. L’EDITEUR se réserve cependant
            le droit de répercuter sans délai toute nouvelle taxe ou toute
            augmentation de taux des taxes existantes. En cas d’évolution de la
            tarification, L’EDITEUR s’engage à en informer LE CLIENT, ce dernier
            pouvant décider sans préavis d’interrompre ses engagements
            contractuels pour cette prestation conformément à l'article 16 en
            cas de refus de cette modification. Sans préjudice d’éventuels
            dommages et intérêts, le défaut de paiement par le Client d’une
            facture à son échéance entraîne de plein droit : - l’application
            d’un intérêt de retard égal à trois fois le taux d’intérêt légal,
            sans mise en demeure préalable et à compter du premier jour de
            retard ; - les frais bancaires et de gestion supplémentaires (suivi
            du recouvrement, courriers et frais téléphoniques de relance,
            représentation des rejets de prélèvement bancaire) ; - la suspension
            immédiate des Services avec résiliation de plein droit du Contrat
            sous 30 jours après l’envoi par L’EDITEUR d’une mise en demeure par
            lettre recommandée avec avis de réception demeurée infructueuse.
            <ol start="12">
              <li>
                <strong>
                  <u>RESPONSABILITE ET GARANTIES</u>
                </strong>
              </li>
            </ol>
            LE CLIENT reconnaît avoir souscrit toutes les assurances nécessaires
            et s’engage à en justifier sans délai sur toute demande de
            L’EDITEUR. LE CLIENT déclare et garantit bien connaître les
            caractéristiques et les contraintes de l’Internet, les temps de
            réponse pour consulter, interroger ou transférer les informations.
            LE CLIENT déclare être informé sur les mesures de sécurité mises en
            place par L’EDITEUR, mais reconnaît que l'obligation de sécurité, du
            fait des caractéristiques de l'Internet, s'analyse en une obligation
            de moyens. L’EDITEUR décline toute responsabilité quant à la
            fiabilité, la sincérité, l’exactitude ou la pertinence des
            informations traitées par le Service Applicatif. LE CLIENT est seul
            responsable vis-à-vis des tiers, notamment ses propres clients et
            prospects, et garantit L’EDITEUR contre toute réclamation ou action
            de la part de ces derniers. Il indemnisera L’EDITEUR de toutes
            conséquences (dommages, frais, y compris honoraires d’avocat, etc.)
            ou de toute condamnation prononcée à l’encontre de L’EDITEUR par une
            décision de justice exécutoire. La responsabilité des Parties est
            limitée aux seuls dommages dont la preuve et le lien direct de
            causalité avec la faute commise sont établis par l'autre Partie. De
            convention expresse, les pertes de bénéfices, de revenus, de
            clientèle, d'activité ou de profit espéré, le temps passé par les
            employés ou toute personne mise à disposition, les troubles à
            l'image sont considérés comme des dommages indirects et à ce titre
            non indemnisables. La responsabilité de L’EDITEUR, tous faits
            générateurs et dommages confondus, ne pourra pas dépasser le montant
            total des sommes payées par LE CLIENT au titre du Contrat, sans que
            cette limite ne puisse être supérieure au montant payé par ce
            dernier au titre du Contrat mis en cause pendant les 12 (douze) mois
            précédant ledit événement. L’EDITEUR ne saurait, en outre, être tenu
            responsable de la destruction accidentelle des Données par le Client
            ou un tiers ayant accédé aux Services applicatifs au moyen des
            Identifiants remis au Client. Toute réclamation doit être adressée
            par LE CLIENT dans les 15 (quinze) jours ouvrés suivant la réception
            de la facture couvrant la période pendant laquelle le fait à
            l’origine de la réclamation s’est produit. L’introduction d’une
            réclamation, quelle qu’elle soit, ne saurait autoriser LE CLIENT à
            retenir le paiement d’une quelconque somme arrivée à échéance. Le
            CLIENT s'engage à effectuer les déclarations et formalités
            nécessaires auprès de la Commission Nationale de l'Informatique et
            des Libertés (CNIL), et à porter de manière appropriée à la
            connaissance de chaque Utilisateur ou tiers, selon le cas, les
            mentions prévues par la réglementation applicable en matière de
            traitements de données à caractère personnel. LE CLIENT se porte
            fort du respect de ces engagements par ses employés et à délier
            L’EDITEUR de toute obligation et de toute responsabilité à cet
            égard.
            <ol start="13">
              <li>
                <strong>
                  <u>DUREE</u>
                </strong>
              </li>
            </ol>
            Les présentes conditions prennent effet à compter de la date
            d’ouverture du Service Applicatif définie par la remise au client
            par quelque moyen que ce soit des ses identifiants de connexion,
            pour une période à durée indéterminée, qui court à compter de
            première connexion. Elles sont reconductibles de plein droit de
            manière automatique par périodes égales à la période initiale, sous
            réserve de l’application des dispositions de l’article 14.
            <ol start="14">
              <li>
                <strong>
                  <u>RESILIATION</u>
                </strong>
              </li>
            </ol>
            Les présentes conditions sont résiliables par LE CLIENT au moyen
            d’une lettre recommandée avec accusé de réception adressée au siège
            de L’EDITEUR, moyennant un préavis de 1 mois avant la date de
            renouvellement automatique du contrat. En cas de manquement de l'une
            ou l'autre des parties au présent contrat, et à la condition qu’il
            n’y soit pas remédié dans un délai de 15 jours à compter de la
            réception d'une mise en demeure adressée par lettre recommandée avec
            avis de réception par l'autre partie et caractérisant expressément
            le ou les manquements reprochés, celui-ci pourra être rompu en tout
            ou partie, sans préavis ni formalité judiciaire, et sans préjudice
            de ses autres droits, et sachant que la période commencée restera
            due. En cas de manquement de l’une ou l’autre Partie dans le cadre
            des Prestations Complémentaires, la Partie non fautive aura la
            possibilité de résilier dans les mêmes conditions uniquement la
            Prestation Complémentaire concernée. Sous réserve des dispositions
            légales applicables, L’EDITEUR sera autorisé à résilier de plein
            droit le Contrat en cas de prononcé du redressement ou de la
            liquidation judiciaire du CLIENT. En cas de cessation d’effet des
            présentes conditions, quel qu'en soit le motif, L’EDITEUR sera en
            droit de procéder à l’arrêt immédiat du Service Applicatif et
            restituera sur demande du CLIENT l’ensemble des données relatives à
            ses fichiers sous un format standard lisible sans difficulté dans un
            environnement équivalent. Le CLIENT est informé que les données
            seront effacées à l’issue d’un délai de 15 jours après l'expiration
            du Service Applicatif.
            <ol start="15">
              <li>
                <strong>
                  <u>PROPRIETE INTELLECTUELLE </u>
                </strong>
              </li>
            </ol>
            L’EDITEUR met à disposition du CLIENT des services applicatifs
            soumis aux conditions juridiques prévues par sa pleine propriété de
            licence. L’EDITEUR a développé un ensemble de services dont il est
            et demeure pleinement propriétaire. L’EDITEUR assure la maintenance
            desdits services applicatifs, l’hébergement de l’Application étant
            assuré par un hébergeur extérieur ayant permis à L’EDITEUR la
            concession de droits d’utilisation. Le droit d’utilisation de la
            licence est concédé au CLIENT. Le CLIENT s'interdit de modifier,
            copier, reproduire, télécharger, diffuser, transmettre, vendre,
            distribuer, désassembler, décompiler ou traduire les Solutions en
            une forme compréhensible. L’EDITEUR se réserve le droit exclusif
            d’intervenir sur les Solutions, notamment pour en corriger les
            éventuelles erreurs. Le CLIENT s’interdit donc formellement
            d’intervenir ou de faire intervenir un tiers sur les Solutions. Il
            garantit le respect de cette obligation par les UTILISATEURS. Les
            développements spécifiques réalisés sur les Solutions à la demande
            du CLIENT et réglés par lui resteront la propriété du CLIENT,
            ceux-ci ne pouvant fonctionner indépendamment des Solutions. Tous
            les éléments du Service Applicatif, qu'ils soient visuels ou
            sonores, y compris la technologie sous-jacente, sont protégés par le
            droit d'auteur, les marques ou les brevets. En particulier, toutes
            les informations ou documents contenus dans le Service Applicatif
            ainsi que tous les éléments créés pour le Service Applicatif sont
            soit la propriété exclusive de L’EDITEUR, soit font l'objet d'un
            droit d'utilisation, d'exploitation et/ou de reproduction de leurs
            titulaires respectifs, notamment dans le cadre de conventions
            d’affiliation ou de licences d’utilisation. Sauf dispositions
            explicites, est interdite toute reproduction, représentation,
            modification, publication, transmission, dénaturation, totale ou
            partielle du Service Applicatif ou de son contenu, par quelque
            procédé que ce soit, et sur quelque support que ce soit sans
            l’autorisation écrite au préalable de L’EDITEUR. Le cas échéant,
            toute exploitation non autorisée du Service Applicatif ou de son
            contenu, des informations qui y sont divulguées engagerait la
            responsabilité de l’UTILISATEUR et constituerait une contrefaçon
            sanctionnée par les articles L 335-2 et suivants du Code de la
            Propriété Intellectuelle. Les bases de données figurant, le cas
            échéant, sur le Service Applicatif qui sont protégées par les
            dispositions de la loi du 1er juillet 1998 portant transposition
            dans le Code de la Propriété Intellectuelle de la Directive
            Européenne du 11 mars 1996 relative à la protection juridique des
            bases de données. A ce titre, toute reproduction ou extraction
            engagerait la responsabilité de l'utilisateur. Les marques
            StartMyStory,{' '}
            <a href="https://www.startmystory.fr">www.startmystory.fr</a>,
            INFLUA ainsi que les logos figurant sur le Service Applicatif sont
            des marques déposées. Toute reproduction ou représentation totale ou
            partielle de ces marques ou de ces logos, seules ou intégrées à
            d'autres éléments, sans l'autorisation expresse et préalable DE
            L’EDITEUR est prohibée, et engagerait la responsabilité de
            l'utilisateur au sens des articles L 713-2 et L 713-3 du Code de la
            Propriété Intellectuelle. Les liens hypertextes mis en œuvre au sein
            du Service Applicatif en direction d'autres sites et/ou de pages
            personnelles et d'une manière générale vers toutes ressources
            existantes sur internet ne sauraient engager la responsabilité de
            L’EDITEUR, qui n'est pas responsable du contenu et du fonctionnement
            de ces sites tiers, ainsi que des éventuels préjudices ou dommages
            (virus ...), de quelque nature qu'ils soient, subis par
            l’utilisateur à l'occasion d'une connexion sur ces sites.
            <ol start="16">
              <li>
                <strong>
                  <u>CONFIDENTIALITE, SOUS-TRAITANCE ET NON-SOLLICITATION</u>
                </strong>
              </li>
            </ol>
            L’EDITEUR et Le CLIENT, ainsi que tout collaborateur ou partenaire
            intervenant pour leur compte, s'engagent respectivement à considérer
            comme confidentiels, pendant toute la durée d’effet de la convention
            et après son expiration, l'ensemble des documents, logiciels,
            savoir-faire, données et informations matériels et immatériels,
            communiqués par l'autre partie pour l'exécution des présentes et à
            ne pas les utiliser ou faire utiliser en dehors des besoins du
            Contrat. En particulier, Le CLIENT s’interdit d’utiliser les
            spécifications de l’application et du Service Applicatif pour créer
            ou permettre les créations d’une application ou d’un Service
            Applicatif ayant une destination similaire. Cet engagement est
            applicable pendant toute la durée de la convention et le demeure
            pendant toute la durée de conservation des données par Le CLIENT.
            L’EDITEUR est en droit de sous-traiter toute ou partie de ses
            prestations au(x) sous-traitant(s) de son choix. L’EDITEUR sera
            entièrement responsable de son ou ses sous-traitants vis-à-vis du
            CLIENT. Le CLIENT s'engage, pendant la durée de la convention et un
            an après son expiration ou sa résiliation, pour quelque cause que ce
            soit, à ne pas recruter ou tenter de recruter toute personne
            participant ou ayant, à tout moment pendant la durée de la
            convention, participé directement à l’activité de INFLUA, sauf
            accord préalable et écrit de INFLUA. En cas de manquement, Le CLIENT
            s'engage à verser à INFLUA, une indemnité forfaitaire égale au
            montant de la rémunération brute perçue pendant les 12 (douze) mois
            précédant le départ de la personne concernée.
            <ol start="17">
              <li>
                <strong>
                  <u>REFERENCES</u>
                </strong>
              </li>
            </ol>
            L’EDITEUR pourra, le cas échéant, mentionner le nom du CLIENT sur
            une liste de références tant pour des besoins de communication
            interne qu’externe, et ce au-delà de la durée d’exécution du
            contrat, sauf refus exprès de la part du CLIENT.
            <ol start="18">
              <li>
                <strong>
                  <u>FORCE MAJEURE ET FAIT D’UN TIERS</u>
                </strong>
              </li>
            </ol>
            L’EDITEUR ne sera pas tenu pour responsable de tout retard ou
            inexécution, lorsque la cause du retard ou de l'inexécution serait
            due à l’intervention d’un tiers ou à la survenance d'un cas de force
            majeure constitué, outre ceux habituellement reconnus par la
            jurisprudence et sans que cette lise soit exhaustive, par les grèves
            totales ou partielles, internes ou externes à l'entreprise,
            intempéries, épidémie, blocage des moyens de transport ou
            d'approvisionnement, pour quelque raison que ce soit, tremblement de
            terre, l'incendie, tempête, inondation, dégâts des eaux,
            restrictions gouvernementales ou légales, modifications légales ou
            réglementaires des formes de commercialisation, le blocage des
            télécommunications y compris le réseau public et tous autres cas
            indépendants de la volonté expresse des parties empêchant
            l'exécution normale de la présente convention. Le cas de force
            majeure suspend les obligations nées du présent contrat pendant
            toute la durée de son existence. Cependant si le cas de force
            majeure avait une durée d'existence supérieure à 1 mois consécutif,
            il ouvrirait droit à la résiliation de plein droit du présent
            contrat par l'une ou l'autre des parties 8 jours après l'envoi d'une
            lettre recommandée avec accusé de réception notifiant cette
            décision.
            <ol start="19">
              <li>
                <strong>
                  <u>INTEGRALITE ET OPPOSABILITE</u>
                </strong>
              </li>
            </ol>
            La présente convention et ses conditions particulières figurant en
            annexe représentent la totalité et l'intégralité de l'entente
            intervenue entre les parties. Il remplace tout accord, arrangement
            ou contrat antérieur, écrit ou non écrit, conclu entre les parties
            et se rapportant au même objet. Dans l'hypothèse où l'une des
            dispositions des présentes conditions serait nulle et non avenue par
            un changement de législation, de réglementation ou par une décision
            de justice, cela ne saurait en aucun cas affecter la validité et le
            respect des autres conditions. Les titres des articles n'ont qu'une
            valeur indicative. Le fait pour une partie de ne pas se prévaloir, à
            un moment donné, d’une des stipulations du présent contrat, ne
            pourra être interprété comme une renonciation à faire valoir
            ultérieurement cette même stipulation. Les intitulés des articles du
            présent contrat ne figurent que pour plus de commodité et n’affecte
            en aucune manière le sens des dispositions auxquelles ils font
            référence. En cas de difficultés d’interprétation entre l’un
            quelconque des titres et l’une quelconque des clauses, le contenu de
            la clause prévaudra sur le titre.
            <ol start="20">
              <li>
                <strong>
                  <u>DROIT APPLICABLE ET COMPETENCES </u>
                </strong>
              </li>
            </ol>
            Les litiges découlant de l’interprétation et/ou de l'application des
            présentes conditions sont soumis à l’application de la loi française
            et à la compétence exclusive du tribunal de commerce de BORDEAUX ce,
            même en cas d'appel ou de pluralité de défendeur(s). &nbsp;
          </div>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
